import { useEffect, useState } from 'react'
import { Toaster } from 'react-hot-toast'
import { useLocation } from 'react-router-dom'
import { LoadingAssets } from '../components/shared/LoadingAssets'
import { IMAGES } from '../constants/images'

const Layout = ({ children }: { children: any}) => {
  const location = useLocation();
  const [loadingAssets, setLoadingAssets] = useState<boolean>(true)

  const charge_first = window.sessionStorage.getItem('charge_loading_first') ?? 'false'

  const loadImages = () => {
    const imagesToLoad = IMAGES.map((image) =>
      fetch(image)
        .then((response) => {
          if (!response.ok) {
            throw new Error(`Error cargando la imagen: ${image}`);
          }
        })
    )

    return Promise.all(imagesToLoad);
  }

  useEffect(() => {
    if (charge_first && loadingAssets) {
      setTimeout(() => {
        loadImages()
          .then(() => {
            setLoadingAssets(false)
            window.sessionStorage.setItem('charge_loading_first', 'true')
          })
          .catch((err) => console.error('Error al cargar las imágenes:', err));
      }, 4000);
    }
  }, [location]);

  return (
         <>
                {
                  loadingAssets &&
                  <LoadingAssets />
                }
                <main className="main">
                    {
                      !loadingAssets &&
                      children
                    }
                </main>
                <Toaster
                  position="top-right"
                  reverseOrder={false}
                />
         </>
  )
}

export default Layout
