import { useEffect, useState } from 'react';

import { CARD_REACTION } from '../../constants/card-reaction';
import { CARDS_ULR } from '../../constants/cards';

interface CardProps {
  discipline?: any;
  isItem?: boolean;
  state: string | null,
  children: any
}

const DisciplineCard = ({ discipline, isItem = false, state, children }: CardProps) => {
  const [reaction, setReaction] = useState<string | null>(null)
  const [card, setCard] = useState<string>(CARD_REACTION.NONE)
  const [icon, setIcon] = useState<string>('')

  useEffect(() => {
    if (discipline) {
      const _url = CARDS_ULR.find(url => url.includes(discipline.id))
      if (_url) setIcon(_url)
    }
    setReaction(state ?? '')
  }, [])

  useEffect(() => {
    if (discipline) {
      setReaction(state ?? '')
      if (state) setCard(CARD_REACTION[state as 'LOVE' | 'LIKE' | 'N/A' | 'DISLIKE' | 'NONE'])
    }
  }, [state])

  return (
    <div key={discipline.id} className={`${isItem ? 'w-[610px] h-[405px] max-[480px]:w-[320px]' : 'w-[274px] h-fit'}  flex flex-col items-center justify-center gap-3 my-4 z-50`}>
      <div className={`w-full ${isItem ? 'w-full min-h-[150px] min-w-[260px] xl:min-h-[210px] max-h-[150px] max-w-[260px] xl:max-h-[210px] xl:max-w-[405px] xl:min-w-[405px] px-[40px] xl:px-[4.5rem] mb-3 pt-[50px] xl:pt-20' : 'h-[285px] pt-24 '} ${reaction === 'LOVE' ? 'drop-shadow-[0_0px_10px_rgb(225,225,225,.8)]' : ''} relative flex flex-col gap-y-2 xl:gap-y-4 items-start pb-[15px] xl:pb-14 px-14 bg-contain bg-no-repeat bg-center`} style={{ backgroundImage: `url(${card})` }}>
        { icon && <img className="slick-item-icon" src={icon} height="30" width="30" alt={discipline?.name} />}
        <p className={`${isItem ? 'text-[16px] leading-[18px] xl:text-[24px] xl:leading-[26px]' : 'text-[27px] leading-[27px]'} w-full text-center ff-cp--bold`} style={{ color: isItem ? discipline?.color : 'white' }}>
            {discipline?.name}
        </p>
          <div className={`${isItem ? 'text-[11px] leading-[14px] xl:leading-[20px] xl:text-[14px] w-full' : 'text-[15px] leading-[18px]'} ff-m--regular`} style={{ color: isItem ? 'white' : discipline?.color }}>
              {discipline?.description}
          </div>
      </div>
      { children }
    </div>
  )
}

export default DisciplineCard
