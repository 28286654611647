import { useEffect, useState } from 'react'

// Services
import { getAllFeaturesByType } from '../../../services/apis/feature'
import { createProfilesByList } from '../../../services/apis/profile'
import { updateRecord } from '../../../services/apis/record'

// Components
import DragReaction from '../../../components/cards/DragReaction'
import BadgeStep from '../../../components/shared/BadgeStep'
import ButtonRedirect from '../../../components/shared/ButtonRedirect'
import TalentCard from '../../../components/shared/TalentCard'
import TitleMessage from '../../../components/shared/TitleMessage'

// Methods
import { getRecord } from '../../../common/methods/storage'

// Constants
import { PAGES } from '../../../constants/Pages'

function Talents () {
  const record = getRecord()

  const [features, setFeatures] = useState<any>([])
  const [selectedList, setSelectedList] = useState<any[]>([])
  const [currentFeatureIndex, setCurrentFeatureIndex] = useState<number>(0)

  const [isLoading, setIsLoading] = useState<boolean>(true)

  useEffect(() => {
    getFeatures()
  }, [])

  const onNextFeature = () => {
    if (currentFeatureIndex < features.length - 1) {
      setCurrentFeatureIndex(currentFeatureIndex + 1)
    } else {
      onGoToNextStep()
    }
  }

  const onPrevFeature = () => {
    if (currentFeatureIndex > 0) {
      setCurrentFeatureIndex(currentFeatureIndex - 1)
    } else {
      // Si ya estamos en el primer feature, realiza la lógica que necesites (puede ser volver a la pantalla anterior)
    }
  }

  const getFeatures = async () => {
    await getAllFeaturesByType('talent')
      .then(data => {
        setFeatures(data.data)
        updateSelectedList()
        setIsLoading(false)
      })
  }

  const updateSelectedList = () => {
    if (record) {
      let talents: any[] = record.features.filter((fet: any) => fet.type === 'talent')

      talents = talents.map((talent: any) => ({
        ...talent,
        feature: talent,
        reaction: talent.profiles.reaction,
        feature_id: talent.id,
        weight: null,
        selected: null
      }))

      setSelectedList([...talents ?? []])
    }
  }

  const onSetSelectedList = (data: any, reaction: string) => {
    const selected_list = selectedList
    const index = selected_list.findIndex(sl => sl.id === data.id)

    if (index === -1) selected_list.push({ feature: data, ...data, reaction, weight: null, selected: null, feature_id: data.id })
    else selected_list[index].reaction = reaction

    setSelectedList([...selected_list])
  }

  const getState = (data: any) => {
    return selectedList.find(sl => sl.id === data.id)?.reaction ?? null
  }

  const onGoToNextStep = async () => {
    await createProfilesByList(selectedList)
      .then(async (data) => {
        if (data.status) await updateRecord({ step: record.type === 'game' ? 27 : 7 })
      })
  }

  return (

    <article className="bg-main bg-loading-page" id="home">
      <div className="w-screen h-screen overflow-hidden quiz-screen-background bg-feature py-4">
        { currentFeatureIndex === 0
          ? (
          <ButtonRedirect action="return" linkReturn={record.type === 'game' ? PAGES.CONVERSATION_5 : PAGES.PROFESSIONS} text="return"/>
            )
          : (
          <ButtonRedirect action="return" callback={onPrevFeature} text="return"/>
            )
        }
        { !isLoading && selectedList.length === features.length && (currentFeatureIndex + 1) === features.length
          ? (
              <ButtonRedirect
                action="next"
                callback={onGoToNextStep}
                linkReturn={record.type === 'game' && record.step_game === 4 ? PAGES.DASHBOARD_HOME : `${PAGES.DISCIPLINES}f2b3b2e0-90a5-11ed-948b-834ce149ae0d`}
                text="next"/>
            )
          : selectedList[currentFeatureIndex] && (
            <ButtonRedirect action="next" callback={onNextFeature} text="next"/>
          )
        }
        <BadgeStep currentStep={6}/>
        <TitleMessage
          index={0}
          useLoading={false}
          size="w-1/2 max-w-[800px] h-auto pl-[10%]"
          title={'¿Qué tanto brillas en estos talentos?'}
          message={'Aumenta el brillo de cada talento para indicar cómo<br />te desenvuelves en cada una de estas áreas.'}
        />

        <div className='w-full absolute z-[500] h-[250px] bottom-[240px] flex justify-center items-center animation-charge py-6 px-[35px] max-[480px]:px-2'>
          {
            !isLoading &&
             <div className="animation-charge w-full h-full max-w-5xl max-h-[350px] relative">
                {
                  features.map((feature: any, index: number) => {
                    return (
                      <div key={feature.id} className={`absolute w-[260px] max-[480px]:w-[150px] flex flex-col gap-4 z-[200] max-[480px]:gap-2 ${index === currentFeatureIndex ? 'block' : 'hidden'} top-[-30px] left-0 right-0 m-auto`}>
                        <TalentCard key={`card${feature.id}`} title={feature.name} state={ getState(feature) }/>
                        <DragReaction key={`drag${feature.id}`} useAlternativeNames feature={feature} currentReaction={ getState(feature) } callback={onSetSelectedList} />
                        <span className="absolute rounded-[30px] flex items-center justify-center w-[120px] h-[30px] max-[480px]:w-[90px] max-[480px]:h-[21px] bg-[#18103E] bottom-[10px] text-sm max-[480px]:text-[11px] max-[480px]:leading-[10px] text-white left-[-110px] max-[480px]:bottom-[5px] max-[480px]:left-[-92px]">“Con dificultad”</span>
                        <span className="absolute rounded-[30px] flex items-center justify-center w-[120px] h-[30px] max-[480px]:w-[90px] max-[480px]:h-[21px] bg-[#18103E] bottom-[10px] text-sm max-[480px]:text-[11px] max-[480px]:leading-[10px] text-white right-[-110px] max-[480px]:bottom-[5px] max-[480px]:right-[-92px]">“Brillante”</span>
                      </div>
                    )
                  })
                }
              </div>
           }
        </div>
      </div>
    </article>
  )
}

export default Talents
