import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

// Methods
import { getWorkspaceParams } from '../../../common/methods/params'
import { getShield } from '../../../common/methods/Shield'
import { getRecord, getTutorialStatus } from '../../../common/methods/storage'

// Components
import ConstelationChart from '../../../components/charts/ConstelationChart'
import BadgeStep from '../../../components/shared/BadgeStep'
import ButtonRedirect from '../../../components/shared/ButtonRedirect'

// Constants
import { STAR_CHART_URL } from '../../../constants/cards'
import { needs } from '../../../constants/lists/needs'
import { PAGES } from '../../../constants/Pages'

// Images
import btnSimpleNext from '../../../assets/images/buttons/btn-simple-next.svg'
import bgCardSimpleMobile from '../../../assets/images/cards/bg-card-simple-mobile.svg'
import bgCardSimple from '../../../assets/images/cards/bg-card-simple.svg'
import { isMobile } from '../../../common/methods/devices'
import { SHIELDS_URL } from '../../../constants/shields'

const ResultsVerbs = () => {
  const navigate = useNavigate()

  const { url } = getWorkspaceParams()
  const record = getRecord()

  const [shield, setShield] = useState<any>(null)

  const getDataShield = () => {
    if (!record) return

    const shield_id = record.shield
    let _shield = null
    let _profiles = record.profiles.filter(({ percentage } : { percentage: number}) => percentage >= 25)
    if (_profiles.length >= 3) {
      _profiles = _profiles.sort((a: any, b: any) => b.percentage - a.percentage)
      const _profiles_for_search = _profiles.slice(0, 2).map((prf: any) => prf.name)
      _shield = SHIELDS_URL.find(shild => {
        const items = shild.items.map(it => it.title)
        let include = true
        for (const profile of _profiles_for_search) {
          if (!items.includes(profile)) {
            include = false
          }
        }

        return shild.items.length >= 3 && include
      })
    }
    const shieldData = getShield(shield_id)

    if (_shield) {
      shieldData.name = _shield.name
      shieldData.chart = _shield.chart
      shieldData.sizeChart = _shield.sizeChart
      shieldData.positionsHigh = _shield.positionsHigh
      shieldData.positionsLow = _shield.positionsLow
    }

    setShield(shieldData)
  }

  const getVerbs = () => {
    if (!record || !shield) return []

    const { features } = record

    const mostHighFeatures = features.filter((feature: any) => needs.includes(feature.type) && [3, 4].includes(parseInt(feature.profiles.weight)))

    let mainFeatures: any[] = []
    let secondayFeatures: any[] = []

    if (shield.type === 'SIMPLE') {
      mainFeatures = mostHighFeatures.filter((feature: any) => feature.parent_id === shield.id)
      secondayFeatures = mostHighFeatures.filter((feature: any) => feature.parent_id !== shield.id)
    } else {
      mainFeatures = features.filter((feature: any) => needs.includes(feature.type) && parseInt(feature.profiles.weight) === 4)
      secondayFeatures = features.filter((feature: any) => parseInt(feature.profiles.weight) === 3)
    }

    mainFeatures = mainFeatures.map((verb: any, index: number) => ({
      name: verb.name,
      star: STAR_CHART_URL.find(star => star.includes(verb.parent_id)),
      position: shield.positionsHigh[index]
    }))

    secondayFeatures = secondayFeatures.map((verb: any, index: number) => ({
      name: verb.name,
      star: STAR_CHART_URL.find(star => star.includes(verb.parent_id)),
      position: shield.positionsLow[index]
    })).slice(0, 6)

    const verbs = [...mainFeatures, ...secondayFeatures]

    return verbs
  }

  useEffect(() => {
    getDataShield()
  }, [])

  return (
        <article className="bg-dashboard constelation-loading" id="home">
            <section className="w-screen h-screen quiz-screen-background relative bg-compass flex flex-col">
                <ButtonRedirect text="" action="return" linkReturn={record?.last_page ?? PAGES.RESULT.VOCATIONAL} />
                {
                  getTutorialStatus() &&
                  <ButtonRedirect
                      action="next"
                      text="next"
                      linkReturn={PAGES.DASHBOARD_HOME}
                  />
                }
                <BadgeStep type="user" />
                <div className={`description-constelation fixed left-[50px] top-0 bottom-0 w-[264px] h-[151px] m-auto  z-[60] bg-no-repeat bg-center bg-cover max-[480px]:m-0 max-[480px]:top-4 max-[480px]:left-2.5 max-[480px]:right-2.5 max-[480px]:w-[calc(100%-20px)] max-[480px]:h-auto max-[480px]:aspect-[4.8] ${isMobile() ? '' : 'right-arrow'}`} style={{ backgroundImage: `url(${isMobile() ? bgCardSimpleMobile : bgCardSimple})` }}>
                    <div className="w-full h-full flex items-center justify-center py-5 px-6">
                        {
                            shield &&
                            <p className="ff-m--regular text-[12px] leading-none text-white max-[480px]:text-[11px] max-[480px]:text-center">{shield.description}</p>
                        }
                    </div>
                </div>
               {
                !getTutorialStatus() &&
                 <div onClick={() => navigate(`/${url}${PAGES.RESULT.NOUNS}`)} className="fixed right-[50px] top-0 bottom-0 w-[230px] h-[55.5px] m-auto z-[60] bg-no-repeat bg-center bg-cover cursor-pointer transition-all ease-in-out hover:scale-105" style={{ backgroundImage: `url(${btnSimpleNext})` }}>
                    <div className="w-full h-full py-4 pl-6">
                        <p className="ff-m-regular text-[10px] leading-none text-white">Constelación</p>
                        <p className="ff-cp-bold text-[14px] leading-none text-white">Sustantivos</p>
                    </div>
                </div>
               }
                <div className={`bg-result-loading w-full h-full relative flex flex-col items-center justify-center py-2 max-[480px]:overflow-hidden max-[480px]:pt-[calc(((100%-20px)*10/47)+60px)] ${isMobile() ? '' : 'bg-feature-big-simple before:w-[648px] before:h-[648px] '}`}>
                    <div className="h-full flex flex-col gap-y-2 items-center justify-center flex-1 px-10 py-4 z-50 max-[480px]:pt-2 max-[480px]:justify-start">
                        {
                            shield &&
                            <>
                                <div className="title-constelation w-[375px] h-[70.5px] bg-center bg-no-repeat bg-full max-[480px]:w-[250px] max-[480px]:h-[47px] max-[480px]:bg-contain" style={{ backgroundImage: `url(${shield.card})` }}>
                                    <div className="w-full h-full max-[480px]:h-[50px] flex flex-col items-center justify-center pt-1 pb-4 px-5 max-[480px]:pb-3">
                                        <p className="ff-m--regular text-white text-xs max-[480px]:text-[11px] max-[480px]:leading-[12px]">Tu Constelación Vocacional</p>
                                        <div className="flex gap-x-1 items-center justify-center">
                                          {
                                              shield.items.map((item: any, index: number) => {
                                                return (
                                                      <p key={item.color + item.title} className="ff-cp--bold text-xl max-[480px]:text-[11px] max-[480px]:leading-[.8rem]" style={{ color: item.color }}>{item.title}</p>
                                                )
                                              })
                                          }
                                        </div>
                                    </div>
                                </div>
                                <div className="content-constelation flex items-center justify-center max-[480px]:scale-[.38] max-[480px]:h-[350px]">
                                    <ConstelationChart size={shield.sizeChart} chart={shield.chart} features={getVerbs()} />
                                </div>
                            </>
                        }
                    </div>
                </div>
            </section>
        </article>
  )
}

export default ResultsVerbs
