import conquistador from '../assets/images/shields/shield-7d30fe80-91e3-11ed-be92-d7ec6112838f.svg'
import buceador from '../assets/images/shields/shield-84740380-91e4-11ed-be92-d7ec6112838f.svg'
import tejedor from '../assets/images/shields/shield-9c58a6e0-91e4-11ed-be92-d7ec6112838f.svg'
import creador from '../assets/images/shields/shield-f4819490-91e3-11ed-be92-d7ec6112838f.svg'

import innovador from '../assets/images/shields/shield-0aef4197-acde-44c6-9bc0-eac825284b4c.svg'
import compositor from '../assets/images/shields/shield-2ba5f3db-fb1b-410c-b489-7748816876e0.svg'
import estrategico from '../assets/images/shields/shield-75bc05a8-56b3-4038-be61-f90517e28eb5.svg'
import defensor from '../assets/images/shields/shield-7a952ed8-4a3c-4340-857f-0328586750be.svg'
import conciliador from '../assets/images/shields/shield-845efc3f-339d-4de5-944f-0d30eb9dbc50.svg'
import comunicador from '../assets/images/shields/shield-ec560d93-8493-41c1-9b10-7f2e24e57624.svg'

import holistico from '../assets/images/shields/shield-41954991-f461-4c32-a29d-a9fe9e973ada.svg'
import confrontador from '../assets/images/shields/shield-683fe486-8130-42cc-9eb6-796586a840bf.svg'
import cohesionador from '../assets/images/shields/shield-6f589a12-efd2-4ad7-a0d7-f3cf245fe8d3.svg'
import iluminador from '../assets/images/shields/shield-c259d988-1656-4255-a042-893d8fca5640.svg'
import inspirador from '../assets/images/shields/shield-f88b0fc5-87b5-4bb1-aed9-4b39d8f985f9.svg'

import starReverseBlueGreen from '../assets/images/stars/star-card-reverse-blue-green.svg'
import starReverseBlueYellow from '../assets/images/stars/star-card-reverse-blue-yellow.svg'
import starReverseBlue from '../assets/images/stars/star-card-reverse-blue.svg'
import starReverseGreenRed from '../assets/images/stars/star-card-reverse-green-red.svg'
import starReverseGreen from '../assets/images/stars/star-card-reverse-green.svg'
import starReverseRedBlue from '../assets/images/stars/star-card-reverse-red-blue.svg'
import starReverseRed from '../assets/images/stars/star-card-reverse-red.svg'
import starReverseYellowGreen from '../assets/images/stars/star-card-reverse-yellow-green.svg'
import starReverseYellowRed from '../assets/images/stars/star-card-reverse-yellow-red.svg'
import starReverseYellow from '../assets/images/stars/star-card-reverse-yellow.svg'

import constelationConquerorWeaver from '../assets/images/constelations/constelation-conqueror-weaver.svg'
import constelationConqueror from '../assets/images/constelations/constelation-conqueror.svg'
import constelationCreatorConqueror from '../assets/images/constelations/constelation-creator-conqueror.svg'
import constelationCreatorDiver from '../assets/images/constelations/constelation-creator-diver.svg'
import constelationCreator from '../assets/images/constelations/constelation-creator.svg'
import constelationDiverConqueror from '../assets/images/constelations/constelation-diver-conqueror.svg'
import constelationDiver from '../assets/images/constelations/constelation-diver.svg'
import constelationWeaverCreator from '../assets/images/constelations/constelation-weaver-creator.svg'
import constelationWeaverDiver from '../assets/images/constelations/constelation-weaver-diver.svg'
import constelationWeaver from '../assets/images/constelations/constelation-weaver.svg'

export const PROFILE_COLORS = [
  { title: '+', color: '#FFFFFF' },
  { title: 'Conquistador', color: '#F75748' },
  { title: 'Creador', color: '#F8E24D' },
  { title: 'Tejedor', color: '#336CFF' },
  { title: 'Buceador', color: '#82F558' }
]

export const SHIELDS_URL:{ id: string, name: string, type: 'SIMPLE' | 'COMPLEX', description: string, sizeChart: string, chart:string, card: string, items: any[], shield: string, positionsHigh?: string[], positionsLow?: string[]}[] = [
  {
    id: '7d30fe80-91e3-11ed-be92-d7ec6112838f',
    name: 'Conquistador',
    description: 'Los conquistadores son personas activas y orientadas a buscar resultados objetivos con los cuales puedan dejar un legado de valor. A la hora de tomar decisiones su pregunta decisiva es: ¿Esto me conviene?',
    items: [
      { title: 'Constelación Conquistador', color: '#F75748' }
    ],
    type: 'SIMPLE',
    card: starReverseRed,
    chart: constelationConqueror,
    sizeChart: 'w-[664.56px] h-[482.97px]',
    shield: conquistador,
    positionsHigh: [
      'top-[117px] left-[288px]',
      'top-[462px] left-[288px]',
      'top-[117px] left-[190px]',
      'top-[117px] right-[190px]',
      'top-[403px] left-[232px]',
      'top-[403px] right-[232px]'
    ],
    positionsLow: [
      'top-[117px] -left-[35px]',
      'top-[117px] -right-[35px]',
      'top-[403px] -left-[35px]',
      'top-[403px] -right-[35px]',
      '-top-[8px] left-[190px]',
      '-top-[8px] right-[190px]'
    ]
  },
  {
    id: '9c58a6e0-91e4-11ed-be92-d7ec6112838f',
    name: 'Tejedor',
    description: 'Los tejedores son personas receptivas e interesadas en desarrollar sus vínculos con otros seres. El legado que quieren dejar es de un mundo de bienestar, unión y armonía entre todos.',
    items: [
      { title: 'Constelación Tejedor', color: '#336CFF' }
    ],
    type: 'SIMPLE',
    card: starReverseBlue,
    chart: constelationWeaver,
    sizeChart: 'w-[702.68px] h-[470.36px]',
    shield: tejedor,
    positionsHigh: [
      'top-[194px] left-[305px]',
      'top-[248px] left-[190px]',
      'top-[248px] right-[200px]',
      'top-[334px] left-[166px]',
      'top-[336px] right-[182px]',
      'top-[436px] left-[303px]'
    ],
    positionsLow: [
      'top-[114px] left-[303px]',
      'top-[70px] left-[260px]',
      'top-[70px] right-[260px]',
      'top-[0px] left-[305px]',
      'top-[83px] -left-[30px]',
      'top-[83px] -right-[30px]'
    ]
  },
  {
    id: 'f4819490-91e3-11ed-be92-d7ec6112838f',
    name: 'Creador',
    description: 'Los creadores son personas activas orientadas al cambio buscando dejar un legado de novedad en el mundo. Su pregunta para tomar decisiones es: ¿Esto me gusta?',
    items: [
      { title: 'Constelación Creador', color: '#F8E24D' }
    ],
    type: 'SIMPLE',
    card: starReverseYellow,
    chart: constelationCreator,
    sizeChart: 'w-[505.26px] h-[490.76px]',
    shield: creador,
    positionsHigh: [
      'top-[102px] left-[205px]',
      'top-[210px] left-[83px]',
      'top-[210px] right-[83px]',
      'top-[278px] left-[113px]',
      'top-[278px] right-[113px]',
      'top-[470px] left-[205px]'
    ],
    positionsLow: [
      'top-[195px] -left-[35px]',
      'top-[195px] -right-[35px]',
      'top-[385px] -left-[35px]',
      'top-[390px] -right-[35px]',
      'top-[52px] left-[205px]',
      '-top-[3px] left-[205px]'
    ]
  },
  {
    id: '84740380-91e4-11ed-be92-d7ec6112838f',
    name: 'Buceador',
    description: 'Los buceadores son personas receptivas orientadas a lo metódico y a zambullirse en mares de conocimiento. Se preguntan constantemente sobre la lógica de las cosas para ir en búsqueda de la verdad.',
    items: [
      { title: 'Constelación Buceador', color: '#82F558' }
    ],
    type: 'SIMPLE',
    card: starReverseGreen,
    chart: constelationDiver,
    sizeChart: 'w-[692px] h-[346px]',
    shield: buceador,
    positionsHigh: [
      'top-[52px] left-[300px]',
      'top-[268px] left-[300px]',
      'top-[10px] right-[242px]',
      'top-[10px] left-[242px]',
      'top-[305px] left-[242px]',
      'top-[305px] right-[242px]'
    ],
    positionsLow: [
      'top-[155px] left-[80px]',
      'top-[155px] right-[80px]',
      '-top-[4px] -left-[32px]',
      '-top-[4px] -right-[32px]',
      'top-[324px] -left-[34px]',
      'top-[324px] -right-[34px]'
    ]
  },
  {
    id: '0aef4197-acde-44c6-9bc0-eac825284b4c',
    name: 'Conquistador + Creador',
    description: 'Las personas que tienen la integración entre creador y conquistador son activas hacia realizar innovaciones que brinden un valor al mundo. De esta manera buscan dejar un legado de inspiración.',
    items: [
      { title: 'Creador', color: '#F8E24D' },
      { title: '+', color: '#FFFFFF' },
      { title: 'Conquistador', color: '#F75748' }
    ],
    type: 'COMPLEX',
    card: starReverseYellowRed,
    chart: constelationCreatorConqueror,
    sizeChart: 'w-[505.26px] h-[490.76px]',
    shield: innovador,
    positionsHigh: [
      'top-[102px] left-[205px]',
      'top-[210px] left-[83px]',
      'top-[210px] right-[83px]',
      'top-[278px] left-[113px]',
      'top-[278px] right-[113px]',
      'top-[470px] left-[205px]'
    ],
    positionsLow: [
      'top-[195px] -left-[35px]',
      'top-[195px] -right-[35px]',
      'top-[385px] -left-[35px]',
      'top-[390px] -right-[35px]',
      'top-[52px] left-[205px]',
      '-top-[3px] left-[205px]'
    ]
  },
  {
    id: '7a952ed8-4a3c-4340-857f-0328586750be',
    name: 'Conquistador + Tejedor',
    description: 'Las personas que tienen la integración entre la vocación de conquistador y tejedor son receptivas ante las necesidades de otros y activas hacia luchar por ellas.',
    items: [
      { title: 'Conquistador', color: '#F75748' },
      { title: '+', color: '#FFFFFF' },
      { title: 'Tejedor', color: '#336CFF' }
    ],
    type: 'COMPLEX',
    card: starReverseRedBlue,
    chart: constelationConquerorWeaver,
    sizeChart: 'w-[702.68px] h-[470.36px]',
    shield: defensor,
    positionsHigh: [
      'top-[194px] left-[305px]',
      'top-[248px] left-[190px]',
      'top-[248px] right-[200px]',
      'top-[334px] left-[166px]',
      'top-[336px] right-[182px]',
      'top-[436px] left-[303px]'
    ],
    positionsLow: [
      'top-[114px] left-[303px]',
      'top-[70px] left-[260px]',
      'top-[70px] right-[260px]',
      'top-[0px] left-[305px]',
      'top-[83px] -left-[30px]',
      'top-[83px] -right-[30px]'
    ]
  },
  {
    id: '75bc05a8-56b3-4038-be61-f90517e28eb5',
    name: 'Conquistador + Buceador',
    description: 'Las personas que tienen la integración entre la vocación de conquistador y buceador son receptivas al conocimiento y activas al usarlo para obtener resultados.',
    type: 'COMPLEX',
    items: [
      { title: 'Buceador', color: '#82F558' },
      { title: '+', color: '#FFFFFF' },
      { title: 'Conquistador', color: '#F75748' }
    ],
    card: starReverseGreenRed,
    chart: constelationDiverConqueror,
    sizeChart: 'w-[664.56px] h-[482.97px]',
    shield: estrategico,
    positionsHigh: [
      'top-[117px] left-[288px]',
      'top-[462px] left-[288px]',
      'top-[117px] left-[190px]',
      'top-[117px] right-[190px]',
      'top-[403px] left-[232px]',
      'top-[403px] right-[232px]'
    ],
    positionsLow: [
      'top-[117px] -left-[35px]',
      'top-[117px] -right-[35px]',
      'top-[403px] -left-[35px]',
      'top-[403px] -right-[35px]',
      '-top-[8px] left-[190px]',
      '-top-[8px] right-[190px]'
    ]
  },
  {
    id: 'ec560d93-8493-41c1-9b10-7f2e24e57624',
    name: 'Creador + Tejedor',
    description: 'Las personas que tienen la integración entre creador y tejedor son activas hacia realizar creaciones que respondan a las necesidades de un otro.',
    items: [
      { title: 'Creador', color: '#F8E24D' },
      { title: '+', color: '#FFFFFF' },
      { title: 'Tejedor', color: '#336CFF' }
    ],
    type: 'COMPLEX',
    card: starReverseBlueYellow,
    chart: constelationWeaverCreator,
    sizeChart: 'w-[702.68px] h-[470.36px]',
    shield: comunicador,
    positionsHigh: [
      'top-[194px] left-[305px]',
      'top-[248px] left-[190px]',
      'top-[248px] right-[200px]',
      'top-[334px] left-[166px]',
      'top-[336px] right-[182px]',
      'top-[436px] left-[303px]'
    ],
    positionsLow: [
      'top-[114px] left-[303px]',
      'top-[70px] left-[260px]',
      'top-[70px] right-[260px]',
      'top-[0px] left-[305px]',
      'top-[83px] -left-[30px]',
      'top-[83px] -right-[30px]'
    ]
  },
  {
    id: '2ba5f3db-fb1b-410c-b489-7748816876e0',
    name: 'Creador + Buceador',
    description: 'Las personas que tienen la integración entre creador y buceador son personas activas en realizar creaciones con una estructura metódica. Sus ideas surgen mayormente a raíz de la observación y el análisis.',
    items: [
      { title: 'Creador', color: '#F8E24D' },
      { title: '+', color: '#FFFFFF' },
      { title: 'Buceador', color: '#82F558' }
    ],
    type: 'COMPLEX',
    card: starReverseYellowGreen,
    chart: constelationCreatorDiver,
    sizeChart: 'w-[692px] h-[346px]',
    shield: compositor,
    positionsHigh: [
      'top-[52px] left-[300px]',
      'top-[268px] left-[300px]',
      'top-[10px] right-[242px]',
      'top-[10px] left-[242px]',
      'top-[305px] left-[242px]',
      'top-[305px] right-[242px]',
      'top-[324px] -right-[34px]'
    ],
    positionsLow: [
      'top-[155px] left-[80px]',
      'top-[155px] right-[80px]',
      '-top-[4px] -left-[32px]',
      '-top-[4px] -right-[32px]',
      'top-[324px] -left-[34px]'
    ]
  },
  {
    id: '845efc3f-339d-4de5-944f-0d30eb9dbc50',
    name: 'Tejedor + Buceador',
    description: 'Las personas que tienen la integración entre tejedor y buceador son personas receptivas tanto hacia el conocimiento como los sentimientos. Su atención está centrada en poder entender al otro y así atenderlo.',
    items: [
      { title: 'Tejedor', color: '#336CFF' },
      { title: '+', color: '#FFFFFF' },
      { title: 'Buceador', color: '#82F558' }
    ],
    type: 'COMPLEX',
    card: starReverseBlueGreen,
    chart: constelationWeaverDiver,
    sizeChart: 'w-[692px] h-[346px]',
    shield: conciliador,
    positionsHigh: [
      'top-[52px] left-[300px]',
      'top-[268px] left-[300px]',
      'top-[10px] right-[242px]',
      'top-[10px] left-[242px]',
      'top-[305px] left-[242px]',
      'top-[305px] right-[242px]'
    ],
    positionsLow: [
      'top-[155px] left-[80px]',
      'top-[155px] right-[80px]',
      '-top-[4px] -left-[32px]',
      '-top-[4px] -right-[32px]',
      'top-[324px] -left-[34px]',
      'top-[324px] -right-[34px]'
    ]
  },
  {
    id: '6f589a12-efd2-4ad7-a0d7-f3cf245fe8d3',
    name: 'Conquistador + Tejedor + Buceador',
    description: 'Los líderes que integran las vocaciones de conquistador, tejedor y buceador son personas a las cuales les interesa comprender de manera profunda a otros a través de la empatía para enfocar su energía activamente en buscar resultados.',
    items: [
      { title: 'Conquistador', color: '#F75748' },
      { title: '+', color: '#FFFFFF' },
      { title: 'Tejedor', color: '#336CFF' },
      { title: '+', color: '#FFFFFF' },
      { title: 'Buceador', color: '#82F558' }
    ],
    type: 'COMPLEX',
    card: starReverseRedBlue,
    chart: constelationConquerorWeaver,
    sizeChart: 'w-[400px] h-[480px]',
    shield: cohesionador,
    positionsHigh: [
      'top-[150px] left-[155px]',
      'top-[365px] left-[155px]',
      'top-[194px] left-[58px]',
      'top-[194px] right-[58px]',
      'top-[268px] left-[40px]',
      'top-[266px] right-[44px]'
    ],
    positionsLow: [
      'top-[458px] left-[155px]',
      '-top-[5px] left-[155px]',
      'top-[50px] -left-[32px]',
      'top-[50px] -right-[32px]',
      'top-[212px] -left-[34px]',
      'top-[212px] -right-[34px]'
    ]
  },
  {
    id: '683fe486-8130-42cc-9eb6-796586a840bf',
    name: 'Conquistador + Creador + Buceador',
    description: 'Los líderes que integran las vocaciones de conquistador, creador y buceador son personas a las cuales les llama crear a partir de lo metódico algo que pueda ofrecer un resultado.',
    items: [
      { title: 'Conquistador', color: '#F75748' },
      { title: '+', color: '#FFFFFF' },
      { title: 'Creador', color: '#F8E24D' },
      { title: '+', color: '#FFFFFF' },
      { title: 'Buceador', color: '#82F558' }
    ],
    type: 'COMPLEX',
    card: starReverseYellowRed,
    chart: constelationCreatorConqueror,
    sizeChart: 'w-[149.34px] h-[472px]',
    shield: confrontador,
    positionsHigh: [
      '-top-[5px] left-[30px]',
      'top-[20px] -left-[7px]',
      'top-[20px] left-[68px]',
      'top-[174px] left-[30px]',
      'top-[174px] -left-[18px]',
      'top-[174px] -right-[18px]'
    ],
    positionsLow: [
      'top-[245px] left-[0px]',
      'top-[245px] right-[0px]',
      'top-[365px] -left-[2px]',
      'top-[365px] -right-[2px]',
      'top-[445px] left-[30px]',
      'top-[298px] left-[30px]'
    ]
  },
  {
    id: 'c259d988-1656-4255-a042-893d8fca5640',
    name: 'Creador + Tejedor + Buceador',
    description: 'Los líderes que integran las vocaciones de creador, tejedor y buceador son personas a las cuales les interesa comprender de manera profunda a otros a través de la empatía para poder realizar creaciones que respondan a las necesidades de otros.',
    items: [
      { title: 'Creador', color: '#F8E24D' },
      { title: '+', color: '#FFFFFF' },
      { title: 'Tejedor', color: '#336CFF' },
      { title: '+', color: '#FFFFFF' },
      { title: 'Buceador', color: '#82F558' }
    ],
    type: 'COMPLEX',
    card: starReverseBlueYellow,
    chart: constelationWeaverCreator,
    sizeChart: 'w-[618px] h-[479px]',
    shield: iluminador,
    positionsHigh: [
      'top-[188px] left-[213px]',
      'top-[188px] right-[213px]',
      'top-[272px] left-[166px]',
      'top-[272px] right-[166px]',
      'top-[350px] left-[147px]',
      'top-[348px] right-[152px]'
    ],
    positionsLow: [
      'top-[458px] left-[265px]',
      '-top-[5px] left-[130px]',
      '-top-[5px] right-[120px]',
      '-top-[5px] left-[265px]',
      'top-[130px] -left-[22px]',
      'top-[129px] -right-[26px]'
    ]
  },
  {
    id: 'f88b0fc5-87b5-4bb1-aed9-4b39d8f985f9',
    name: 'Conquistador + Creador + Tejedor',
    description: 'Los líderes que integran las vocaciones de conquistador, creador y tejedor son personas a las cuales les atrae la innovación que responda a las necesidades de otros. Buscan resultados realizando creaciones que ayuden.',
    items: [
      { title: 'Conquistador', color: '#F75748' },
      { title: '+', color: '#FFFFFF' },
      { title: 'Creador', color: '#F8E24D' },
      { title: '+', color: '#FFFFFF' },
      { title: 'Tejedor', color: '#336CFF' }
    ],
    type: 'COMPLEX',
    card: starReverseYellowRed,
    chart: constelationCreatorConqueror,
    sizeChart: 'w-[149.34px] h-[472px]',
    shield: inspirador,
    positionsHigh: [
      '-top-[5px] left-[30px]',
      'top-[20px] -left-[7px]',
      'top-[20px] left-[68px]',
      'top-[174px] left-[30px]',
      'top-[174px] -left-[18px]',
      'top-[174px] -right-[18px]'
    ],
    positionsLow: [
      'top-[245px] left-[0px]',
      'top-[245px] right-[0px]',
      'top-[365px] -left-[2px]',
      'top-[365px] -right-[2px]',
      'top-[445px] left-[30px]',
      'top-[298px] left-[30px]'
    ]
  },
  {
    id: '41954991-f461-4c32-a29d-a9fe9e973ada',
    name: 'Conquistador + Creador + Tejedor + Buceador',
    description: 'Los líderes holísticos integran las vocaciones de conquistador, creador, tejedor y buceador. A estas personas les llama tanto su lado receptivo hacia el conocimiento y el mundo emocional como su lado activo hacia los resultados y el mundo expresivo.',
    items: [
      { title: 'Conquistador', color: '#F75748' },
      { title: '+', color: '#FFFFFF' },
      { title: 'Creador', color: '#F8E24D' },
      { title: '+', color: '#FFFFFF' },
      { title: 'Tejedor', color: '#336CFF' },
      { title: '+', color: '#FFFFFF' },
      { title: 'Buceador', color: '#82F558' }
    ],
    type: 'COMPLEX',
    card: starReverseYellowRed,
    chart: constelationCreatorConqueror,
    sizeChart: 'w-[149.34px] h-[472px]',
    shield: holistico,
    positionsHigh: [
      '-top-[5px] left-[30px]',
      'top-[20px] -left-[7px]',
      'top-[20px] left-[68px]',
      'top-[174px] left-[30px]',
      'top-[174px] -left-[18px]',
      'top-[174px] -right-[18px]'
    ],
    positionsLow: [
      'top-[245px] left-[0px]',
      'top-[245px] right-[0px]',
      'top-[365px] -left-[2px]',
      'top-[365px] -right-[2px]',
      'top-[445px] left-[30px]',
      'top-[298px] left-[30px]'
    ]
  }
]
