import { useCallback, useEffect, useState } from 'react'
import { Unity, useUnityContext } from 'react-unity-webgl'
import { getWorkspaceParams } from '../../common/methods/params'

const Game = () => {
  const { url } = getWorkspaceParams()

  const [devicePixelRatio, setDevicePixelRatio] = useState(window.devicePixelRatio)
  const buildUrl = process.env.REACT_APP_GAME_URL + '/Build'

  const { unityProvider, addEventListener, removeEventListener, sendMessage, isLoaded } = useUnityContext({
    loaderUrl: buildUrl + '/game.loader.js',
    dataUrl: buildUrl + '/game.data.unityweb',
    frameworkUrl: buildUrl + '/game.framework.js.unityweb',
    codeUrl: buildUrl + '/game.wasm.unityweb',
    streamingAssetsUrl: 'StreamingAssets'
  })

  useCallback(() => {
    // A function which will update the device pixel ratio of the Unity
    // Application to match the device pixel ratio of the browser.
    const updateDevicePixelRatio = () => {
      setDevicePixelRatio(window.devicePixelRatio)
    }
    // A media matcher which watches for changes in the device pixel ratio.
    const mediaMatcher = window.matchMedia(`screen and (resolution: ${devicePixelRatio}dppx)`)
    // Adding an event listener to the media matcher which will update the
    // device pixel ratio of the Unity Application when the device pixel
    // ratio changes.
    mediaMatcher.addEventListener('change', updateDevicePixelRatio)
    return function () {
      // Removing the event listener when the component unmounts.
      mediaMatcher.removeEventListener('change', updateDevicePixelRatio)
    }
  }, [devicePixelRatio])

  const handleGameOver = useCallback(() => {
    console.log('responde juego')
  }, [])

  useEffect(() => {
    addEventListener('GameOver', handleGameOver)
    return () => {
      removeEventListener('GameOver', handleGameOver)
    }
  }, [addEventListener, removeEventListener, handleGameOver])

  if (isLoaded) {
    sendMessage('TestReact', 'SendToken', `${localStorage.getItem('tcm_access_token')}`)
    sendMessage('TestReact', 'SendURL', process.env.REACT_APP_WEB_URL + `/${url}/question`)
  }

  return (
        <section id="game">
            <div className="h-screen w-screen relative overflow-hidden bg-[#231F20]">
                <div id="unity-container" className="unity-desktop absolute left-[50%] top-[50%] translate-y-[-50%] translate-x-[-50%]">
                    <Unity
                        unityProvider={unityProvider}
                        className="h-screen bg-[#231F20] aspect-video"
                        devicePixelRatio={devicePixelRatio}
                    />
                </div>
            </div>
        </section>
  )
}

export default Game
